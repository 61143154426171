import React from "react";
import { withStore } from "../../../store";
import Modal from "../Modal";
import { Button, SubscribeButton } from "../../Buttons";
import { User, setStorageItem, Analytics } from "../../../utilities";
import "./TrialProgressModal.scss";

class TrialProgressModal extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      onClose: props.onClose
    }
  };

  async componentDidMount() {
    if(!User.authenticated())
      return;

    const hasExpiredFreeTrial = await User.hasExpiredFreeTrial();

    if(hasExpiredFreeTrial) {
      Analytics.userActionTrialProgressAlert();
      this.setState({
        message: "Your free trial has ended. Please subscribe to continue watching your favourite shows."
      })
    }
    else if(hasExpiredFreeTrial === false) {
      const reminder = User.getCurrentFreeTrialReminder();
      if(reminder >= 0) {
        let message;
        const onClose = () => {
          User.removeFreeTrialReminder(reminder);
          setStorageItem("smr", Date(), false, false, true);
          this.props.onClose && this.props.onClose();
        }

        switch(reminder) {
          case 0:
            message = `Your free trial is ending today. Subscribe now to continue watching.`;
            break;
          case 1:
            message = `Your free trial is ending in 1 day. Subscribe now to continue watching.`;
            break;
          default:
            message = `Your free trial is ending in ${reminder} days. Subscribe now to continue watching.`;
            break;
        }
        Analytics.userActionTrialProgressAlert(reminder);
        this.setState({ message, onClose })
      }
    }
  }

  render() {
    const { message, onClose } = this.state;

    return (
      message ?
      <Modal onClose={onClose}>
        {(onClose) => (
          <div className="TrialProgressModal">
            <div className="TrialProgressModal__message">{message}</div>
            <SubscribeButton text="Subscribe Now" trial={false} />
            <Button text="Decide later" white onClick={onClose} />
          </div>
        )}
      </Modal>
      : null
    )
  };
};

export default withStore(TrialProgressModal);
